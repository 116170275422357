<template> 
    <ul id="card"> 
        <li class="slot"><slot name="prepend"></slot></li>
        <li class="warp" v-for="(item,index) in list" :key="index" :style="{background:bg}">
            <div class="item" :style="{transition:'all 2.5s ease-in-out 0s',top:'-'+item.top+'px'}"> 
                <span v-for="(v,k) in nums" :key="k" :style="{color:fg}">{{v}}</span>
            </div>
        </li>
        <li class="slot"><slot></slot></li>
    </ul>
</template>

<script>
export default { 
    props:{
      number: {
        type: [String, Number],
        default: 0,
      },
      fg: {
        type: String,
        default: "#666",
      },
      bg: {
        type: String,
        default: "transparent",
      },
    }, 
    data(){ 
        return{ 
            list: [], 
            nums: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '.']
        } 
    }, 
    mounted(){ 
        this.scroll(); 
    }, 
    watch:{
        number(val, oldVal) {
          //console.log(oldVal, "->" ,val);
          this.scroll();
        },
    },
    methods:{ 
        scroll(){ 
            let arr = []; 
            this.number.toString().split("").forEach((value) => { 
                arr.push({num:value, top:0 }) 
            }); 
            this.list = arr; 

            let h = parseFloat(getComputedStyle(document.getElementById("card")).height);
            this.list.forEach((item, index) => { 
                setTimeout(() => {
                    item.top = parseFloat(this.nums.join("").indexOf(item.num) * h); 
                }, index * 200); 
            }); 
        }
    } 
}
</script>

<style scoped>
ul {
  margin-top: 3px; 
  height: 40px; 
  text-align: center;
  display: inline-block;
}
li { 
  float: left; 
  margin: 0 1px;
  font-size: 30px; 
  line-height: 40px;
  text-align: center; 
}
.slot {
  display: inline;
}
.warp { 
  position: relative; 
  width: 32px;
  height: 100%; 
  overflow: hidden; 
}
.item { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}
span{ 
  float: left; 
  width: 100%; 
  height: 100%; 
  color: blue;
  text-align: center; 
} 
</style>
