<template>
  <BaseLayout title="量化交易" subtitle="设置好量化策略 机器人帮你交易" :bg="background" fg="#FFF">
    
    <div class="stat" justify="space-between" align="center">
        <div class="desc1">24 小时盈利</div>
        <number-flip class="amount" :number="state.stat.day_profit" fg="#339" bg="#EEE" size="24px"><span style="color:#999;font-size:12px;">USDT</span></number-flip>
        <div class="desc2">24 小时交易 {{state.stat.day_num}} 单，累计交易 {{state.stat.all_num}} 单</div>
    </div>

    <div class="info">

      <div class="intro">
        <div class="what">告别情绪化交易</div>
        智能量化交易机器人（炒币机），让你告别情绪化、劳累的手动交易。币市多空势力经常性出现悬殊差距，在盘面报价迅速上涨时，触发追踪止盈，扩大盈利空间；在盘面报价迅速下跌时，触发防护机制，暂停买入或扩大下单间隔。
        <div class="todo">
          <van-button plain icon="play-circle" icon-position="right" color="#66F" size="small" to="/intro">详细介绍</van-button>
          &nbsp;
          <van-button plain icon="share" icon-position="right" color="#F66" size="small" to="/my/exchange">我要使用</van-button>
        </div>
      </div>

      <div class="list">
        <van-divider class="title" content-position="left" dashed>最近完成的交易</van-divider>
        <div class="item" v-for="(item,index) in state.list" :key="index" @click="showDetail(item)">
          <div class="dt"><van-tag color="#CCC">{{item.ts}}</van-tag></div>
          <van-cell class="data" :border="false" :title="'$ ' + item.profit" :value="item.ratio + ' %'" />
          <van-cell class="unit" :border="false" :title="'盈利'" :value="'盈利率'"/>
          <van-cell class="coin" :border="false" icon-prefix="icon-symbol" :icon="item.symbol.toLowerCase()" :title="item.symbol.toUpperCase() + ' / USDT'" :label="'建仓时间：' + item.dt + ' '" is-link/>
        </div>
      </div>

    </div>

    <van-popup v-model:show="state.popup" position="bottom" transition="van-slide-up" round closeable>
      <van-cell :border="false" icon-prefix="icon-symbol" :icon="state.item.symbol.toLowerCase()" :title="state.item.symbol.toUpperCase() + ' / USDT'" :label="'盈利：' + state.item.profit + ' U ，盈利率：' + state.item.ratio + ' %'"/>
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(log, i) in state.detail.log" :key="i">
          <h4 class="head">{{log.dt + " 「" + ["清仓", "建仓", "加仓", "补仓"][log.type] + "」"}}</h4>
          <p>{{"数量：" + log.num}}</p>
          <p>{{"单价：" + log.price + "，总价：" + log.amount}}</p>
        </van-step>
      </van-steps>
    </van-popup>

  </BaseLayout>
</template>

<style scoped>
.stat {
  margin: 0px 20px 20px 20px;
  background: #FFF;
  border-radius: 5px;
  border:solid 2px #EEE;
  opacity: 0.5;
}
.stat .desc1 {
  margin-top: 5px;
  padding-left: 20px;
  color: #88F;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.stat .desc2 {  
  margin-bottom: 5px;
  color: #666;
  font-size: 12px;
  opacity: 1;
}

.info {
  min-height: 600px;
  padding: 10px;
  background: #F9F9F9;
  border-radius: 20px 20px 0 0;
}


.intro {
  margin: 0px;
  padding: 10px;
  background: #FFF;
  border: solid 1px #CC6;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  line-height: 24px;
  display: block;
}
.what {
  margin-bottom: 5px;
  padding: 3px 0px 3px 5px;
  background: linear-gradient(45deg, #66F, #F66 60%, #FF9);
  background: linear-gradient(45deg, #6FF, #FFF 80%, #FFF);
  color: #339;
}
.todo {
  width: 100%;
  margin-top: 10px;
  text-align: right;
  display: inline-block;
}


.list {
  margin: 0px;
}
.title {
  margin: 30px 5px;
  color: #999;
  border-color: #999;
  font-size: 14px;
}
.item {
  position: relative;
  margin-top: 50px;
  padding: 0px 10px 10px 10px;
  background: #FFF;
  border: solid 1px #EEE;
  border-radius: 10px;
  font-size: 12px;
}
.dt {
  position: relative;
  top: -22px;
  text-align: center;
}
.coin {
  margin: 10px 0;
  height: 60px;
  background: transparent;
  border-top: solid 1px #EEE;
  color: #333;
  font-size: 18px;
}
.data {
  background: transparent;
  color: #090 !important;
  font-size: 28px;
}
.unit {
  padding:0 20px;
  background: transparent;
  color: #999 !important;
  font-size: 12px;
}
.head {
  margin: 0;
}

</style>

<script>
import { reactive } from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'
import NumberFlip from '@/components/NumberFlip.vue'

export default {
  components: {BaseLayout, NumberFlip},

  setup(){
    const state = reactive({
      stat: {
        day_num: 0,
        day_profit: 0,
        all_num: 0,
        all_profit: 0,
      },
      list: [],
      item: {},
      detail: {},
      popup: false,
    });
    return {
      state,
    };
  },

  created() {
    this.$http.get("/exchange/summary").then((data) => this.state.stat = data);
    this.$http.get("/exchange/tasklist?limit=20").then((data) => {
      for (let i in data){
        let item = data[i];
        item.profit > 0 && this.state.list.push({
          id: item.id,
          symbol: item.symbol.replace(/usdt$/, ""),
          profit: item.profit.replace(/(\.\d\d)(\d+)$/, "$1"),
          ratio : (parseFloat(item.ratio)*100).toString().replace(/(\.\d\d)(\d+)$/, "$1"),
          dt: item.dt,
          ts: item.ts,
        });
      }
    });
  },

  computed: {
    background() {
      return "url('" + require('../assets/bg1.jpg') + "') top right";
    },
  },

  methods: {
    showDetail(item) {
      this.state.item = item;
      this.state.detail = {};
      this.$http.post("/exchange/taskdetail?id=" + item.id).then((data) => {
        this.state.detail = data;
        this.state.popup = true;
      });
    },
  }
}
</script>
